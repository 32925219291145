import { FlexContainer } from 'components/FlexContainer';
import { RadioWrapper } from './Wrapper.styled';
import { Input } from './Input.styled';

import type { FC } from 'react';
import type { RadioOptionProps } from './types';

export const RadioOption: FC<RadioOptionProps> = ({ value, onChange, name, option }) => {
  const checked = String(option.value) === String(value);

  return (
    <RadioWrapper maxWidth="75px">
      <FlexContainer alignItems="center" justifyContent="center">
        <Input
          type="radio"
          id={option.label}
          name={name}
          value={option.value}
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={option.label}>{option.label}</label>
      </FlexContainer>
    </RadioWrapper>
  );
};
