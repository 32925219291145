import { type ChangeEvent, type FocusEvent, type FC, useCallback } from 'react';
import { Icon } from '../Icon';
import { InputContainer } from '../InputContainer.styled';
import { Input } from './Input.styled';

import type { IconDirection, IconType } from '../Icon/types';

interface FormInputProps {
  value: number | string;
  onChange: (e: ChangeEvent<HTMLInputElement>, maxLength?: number) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  name: string;
  label: string;
  icon?: IconType;
  iconDirection?: IconDirection;
}

export const FormInput: FC<FormInputProps> = ({
  value,
  onChange,
  onBlur,
  name,
  label,
  maxLength,
  icon,
  iconDirection,
}) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e, maxLength);
    },
    [onChange, maxLength]
  );

  return (
    <InputContainer>
      <label htmlFor={name}>{label}</label>
      {!!icon && <Icon icon={icon} direction={iconDirection} />}
      <Input
        type="number"
        name={name}
        value={value}
        onChange={handleOnChange}
        onBlur={onBlur}
        inputMode="numeric"
        min="0"
        iconDirection={iconDirection}
      />
    </InputContainer>
  );
};
