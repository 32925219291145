export const SVGNote = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M72.4982 11.0179H17.4816C16.0225 11.0179 14.6231 11.5976 13.5914 12.6293C12.5596 13.6611 11.98 15.0605 11.98 16.5196V71.5361C11.98 72.9953 12.5596 74.3946 13.5914 75.4264C14.6231 76.4582 16.0225 77.0378 17.4816 77.0378H54.8551C55.5776 77.039 56.2933 76.8971 56.9608 76.6203C57.6283 76.3435 58.2343 75.9373 58.744 75.4251L76.3872 57.782C76.8993 57.2723 77.3055 56.6662 77.5823 55.9987C77.8591 55.3313 78.001 54.6156 77.9998 53.893V16.5196C77.9998 15.0605 77.4202 13.6611 76.3884 12.6293C75.3567 11.5976 73.9573 11.0179 72.4982 11.0179ZM33.9866 30.2737H55.9932C56.7228 30.2737 57.4225 30.5636 57.9383 31.0794C58.4542 31.5953 58.744 32.295 58.744 33.0246C58.744 33.7541 58.4542 34.4538 57.9383 34.9697C57.4225 35.4856 56.7228 35.7754 55.9932 35.7754H33.9866C33.257 35.7754 32.5573 35.4856 32.0415 34.9697C31.5256 34.4538 31.2358 33.7541 31.2358 33.0246C31.2358 32.295 31.5256 31.5953 32.0415 31.0794C32.5573 30.5636 33.257 30.2737 33.9866 30.2737ZM44.9899 57.782H33.9866C33.257 57.782 32.5573 57.4922 32.0415 56.9763C31.5256 56.4604 31.2358 55.7607 31.2358 55.0312C31.2358 54.3016 31.5256 53.6019 32.0415 53.086C32.5573 52.5702 33.257 52.2803 33.9866 52.2803H44.9899C45.7195 52.2803 46.4192 52.5702 46.935 53.086C47.4509 53.6019 47.7407 54.3016 47.7407 55.0312C47.7407 55.7607 47.4509 56.4604 46.935 56.9763C46.4192 57.4922 45.7195 57.782 44.9899 57.782ZM33.9866 46.7787C33.257 46.7787 32.5573 46.4889 32.0415 45.973C31.5256 45.4571 31.2358 44.7574 31.2358 44.0279C31.2358 43.2983 31.5256 42.5986 32.0415 42.0827C32.5573 41.5669 33.257 41.277 33.9866 41.277H55.9932C56.7228 41.277 57.4225 41.5669 57.9383 42.0827C58.4542 42.5986 58.744 43.2983 58.744 44.0279C58.744 44.7574 58.4542 45.4571 57.9383 45.973C57.4225 46.4889 56.7228 46.7787 55.9932 46.7787H33.9866ZM55.9932 70.398V55.0312H71.3635L55.9932 70.398Z"
      fill="url(#paint0_linear_27_258)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_27_258"
        x1="44.9899"
        y1="11.0179"
        x2="44.9899"
        y2="77.0378"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED6268" />
        <stop offset="0.640625" stopColor="#FC7420" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGNote;
