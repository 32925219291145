import { useMemo } from 'react';

import { TableRow } from './components/TableRow';
import { TableHeaderRow } from './components/TableHeaderRow';
import { StyledTable } from './styled/StyledTable.styled';

import type { FC } from 'react';
import type { TableProps } from './types';

export const Table: FC<TableProps> = ({ columns, rows, marginTop = 4, isLined = false }) => {
  const renderRows = useMemo(
    () =>
      rows.map(({ id, ...props }) => (
        <TableRow isLined={isLined} key={id} id={id} columns={columns} {...props} />
      )),
    [columns, rows, isLined]
  );

  return (
    <StyledTable marginTop={marginTop} isLined={isLined}>
      <thead>
        <TableHeaderRow columns={columns} />
      </thead>
      <tbody>{renderRows}</tbody>
    </StyledTable>
  );
};
