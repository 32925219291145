import { darken } from 'polished';
import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

import type { Highlight } from 'styled/types';

export const TableCellAccent = styled.div<{
  accentColor?: Highlight;
}>`
  width: calc(100% - ${getDimension('size4')});
  height: ${getDimension('size4')};

  right: ${getDimension('size6')};

  position: absolute;
  top: -${getDimension('size4')};

  background-color: ${({ theme, accentColor }) =>
    accentColor && darken(0.15, theme.colors[accentColor])};
`;
