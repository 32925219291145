import { getAnnualDividend } from './getAnnualDividend';
import { getSharePrice } from './getSharePrice';
import { getNumberOfShares } from './getNumberOfShares';
import { getAnnualDividendYield } from './getAnnualDividendYield';

import type { MonthlyDividendIncome } from 'components/DividendCalculator/types';

/**
 * Calculates the average monthly dividend income over the holding period with drp = false
 * @param sharePrice - Share price
 * @param numberOfShares - Number of shares
 * @param holdingPeriod - Holding period
 * @param annualDividendYield - Annual dividend yield
 * @param annualContribution - Annual contribution
 * @param expectedAnnualStockAppreciation - Expected annual stock appreciation
 * @param expectedDividendGrowthRate - Expected dividend growth rate
 * @returns Monthly dividend income as an array of objects for each year in the holding period
 */
export const getAverageMonthlyIncome = (
  sharePrice: number | string,
  numberOfShares: number | string,
  holdingPeriod: number,
  annualDividendYield: number | string,
  annualContribution: number | string,
  expectedAnnualStockAppreciation: number | string,
  expectedDividendGrowthRate: number | string
): MonthlyDividendIncome[] => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualDividendYield = Number(annualDividendYield);
  const resolvedExpectedDividendGrowthRate = Number(expectedDividendGrowthRate);
  const resolvedExpectedAnnualStockAppreciation = Number(expectedAnnualStockAppreciation);
  const resolvedAnnualContribution = Number(annualContribution);

  const income = [];
  let runningSharePrice = resolvedSharePrice;
  let runningNumberOfShares = resolvedNumberOfShares;
  let runningAnnualDividendYield = resolvedAnnualDividendYield;

  while (income.length < holdingPeriod) {
    // Dividends earned for the current year
    const annualDividend = getAnnualDividend(
      runningSharePrice,
      runningNumberOfShares,
      runningAnnualDividendYield
    );

    // Dividends earned for the current year divided by 12 to get monthly dividend income
    const monthlyDividendIncome = annualDividend / 12;

    income.push({
      year: income.length + 1,
      income: monthlyDividendIncome,
    });

    // Total number of shares held
    runningNumberOfShares = getNumberOfShares(
      runningNumberOfShares,
      resolvedAnnualContribution,
      runningSharePrice
    );

    // End of year share price after stock appreciation
    runningSharePrice = getSharePrice(runningSharePrice, resolvedExpectedAnnualStockAppreciation);

    // Dividend yield after dividend growth rate from previous year
    runningAnnualDividendYield = getAnnualDividendYield(
      runningAnnualDividendYield,
      resolvedExpectedDividendGrowthRate
    );
  }

  return income;
};
