export const SVGCalendarBlank = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M71.5216 11.018H63.2691V8.26718C63.2691 7.53762 62.9793 6.83794 62.4634 6.32206C61.9476 5.80618 61.2479 5.51636 60.5183 5.51636C59.7887 5.51636 59.0891 5.80618 58.5732 6.32206C58.0573 6.83794 57.7675 7.53762 57.7675 8.26718V11.018H30.2592V8.26718C30.2592 7.53762 29.9694 6.83794 29.4535 6.32206C28.9376 5.80618 28.2379 5.51636 27.5084 5.51636C26.7788 5.51636 26.0791 5.80618 25.5633 6.32206C25.0474 6.83794 24.7576 7.53762 24.7576 8.26718V11.018H16.5051C15.0459 11.018 13.6466 11.5976 12.6148 12.6294C11.5831 13.6612 11.0034 15.0605 11.0034 16.5197V71.5362C11.0034 72.9953 11.5831 74.3947 12.6148 75.4265C13.6466 76.4582 15.0459 77.0379 16.5051 77.0379H71.5216C72.9807 77.0379 74.3801 76.4582 75.4119 75.4265C76.4436 74.3947 77.0233 72.9953 77.0233 71.5362V16.5197C77.0233 15.0605 76.4436 13.6612 75.4119 12.6294C74.3801 11.5976 72.9807 11.018 71.5216 11.018ZM71.5216 27.523H16.5051V16.5197H24.7576V19.2705C24.7576 20.0001 25.0474 20.6997 25.5633 21.2156C26.0791 21.7315 26.7788 22.0213 27.5084 22.0213C28.2379 22.0213 28.9376 21.7315 29.4535 21.2156C29.9694 20.6997 30.2592 20.0001 30.2592 19.2705V16.5197H57.7675V19.2705C57.7675 20.0001 58.0573 20.6997 58.5732 21.2156C59.0891 21.7315 59.7887 22.0213 60.5183 22.0213C61.2479 22.0213 61.9476 21.7315 62.4634 21.2156C62.9793 20.6997 63.2691 20.0001 63.2691 19.2705V16.5197H71.5216V27.523Z"
      fill="url(#paint0_linear_27_256)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_27_256"
        x1="44.0133"
        y1="5.51636"
        x2="44.0133"
        y2="77.0379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC7420" />
        <stop offset="1" stopColor="#FAAC35" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGCalendarBlank;
