import { getAnnualDividend } from './getAnnualDividend';
/**
 * Calculates the accumulated dividends for a given year
 * = previous year's dividend returns + the current year's dividend return
 * @param sharePrice - Share price
 * @param numberOfShares - Number of shares
 * @param annualDividendYield - Annual dividend yield
 * @param previousAnnualDividends - Previous annual dividends
 * @returns Total dividends
 */
export const getAccumulatedAnnualDividends = (
  sharePrice: number | string,
  numberOfShares: number | string,
  annualDividendYield: number | string,
  previousAnnualDividends?: number
) => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualDividendYield = Number(annualDividendYield);
  const resolvedPreviousAnnualDividends = Number(previousAnnualDividends);

  const annualDividend = getAnnualDividend(
    resolvedSharePrice,
    resolvedNumberOfShares,
    resolvedAnnualDividendYield
  );

  if (resolvedPreviousAnnualDividends) return annualDividend + resolvedPreviousAnnualDividends;

  return annualDividend;
};
