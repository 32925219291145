import { useCallback, useReducer } from 'react';
import { formReducer } from './formReducer';
import type { UseFormUpdateAction } from './types';
import type { ChangeEvent, FocusEvent, Reducer } from 'react';

export function useForm<T extends {} = Record<string, any>>(initialState: T) {
  const [state, dispatch] = useReducer<Reducer<T, UseFormUpdateAction<T>>>(
    formReducer,
    initialState
  );
  // Update the state with new value when it changes
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      dispatch({
        type: 'UPDATE',
        field: e.currentTarget.name,
        payload: e.currentTarget.value,
      });
    },
    [dispatch]
  );

  // Update the state with new value when it changes
  const onNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, maxLength?: number) => {
      let payload = e.currentTarget.value;
      if (maxLength) {
        payload = e.currentTarget.value.slice(0, maxLength);
      }

      dispatch({
        type: 'UPDATE',
        field: e.currentTarget.name,
        payload,
      });
    },
    [dispatch]
  );

  // If the input is empty, when the input blurs - set the value to 0
  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (!e.currentTarget.value) {
        dispatch({
          type: 'UPDATE',
          field: e.currentTarget.name,
          payload: 0,
        });
      }
    },
    [dispatch]
  );

  return { state, dispatch, onChange, onBlur, onNumberChange };
}
