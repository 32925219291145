import { getPrincipal } from 'helpers/dividendCalculator/getPrincipal';
import { getPercentage } from 'helpers/getPercentage';
import { getTotalDividendReturn } from 'helpers/dividendCalculator/getTotalDividendReturn';

import { getTotalContributions } from 'helpers/dividendCalculator/getTotalContributions';
import { getTotalGrowth } from 'helpers/dividendCalculator/getTotalGrowth';
import type { Color } from 'styled/types';

export interface DividendReturnBreakdown {
  name: string;
  color: Color;
  amount: number;
  percentage: number;
}

export const getDividendReturnsBreakdown = (
  sharePrice: number | string,
  numberOfShares: number | string,
  holdingPeriod: number,
  annualDividendYield: number | string,
  annualContribution: number | string,
  dividendReinvestmentPlan: 'yes' | 'no',
  expectedAnnualStockAppreciation: number | string,
  expectedDividendGrowthRate: number | string
): DividendReturnBreakdown[] => {
  const dividends = getTotalDividendReturn(
    sharePrice,
    numberOfShares,
    holdingPeriod,
    annualDividendYield,
    annualContribution,
    dividendReinvestmentPlan,
    expectedAnnualStockAppreciation,
    expectedDividendGrowthRate
  );

  const growth = getTotalGrowth(
    sharePrice,
    numberOfShares,
    holdingPeriod,
    annualDividendYield,
    annualContribution,
    dividendReinvestmentPlan,
    expectedAnnualStockAppreciation,
    expectedDividendGrowthRate
  );
  const principal = getPrincipal(sharePrice, numberOfShares);

  const contributions = getTotalContributions(holdingPeriod, annualContribution);

  const estimatedDividendReturn = dividends + contributions + growth + principal;

  const dividendPercentage = getPercentage(dividends, estimatedDividendReturn);
  const contributionPercentage = getPercentage(contributions, estimatedDividendReturn);
  const growthPercentage = getPercentage(growth, estimatedDividendReturn);
  const principalPercentage = getPercentage(principal, estimatedDividendReturn);

  return [
    {
      name: 'Dividends',
      color: 'yellow',
      amount: dividends,
      percentage: dividendPercentage,
    },
    {
      name: 'Contributions',
      color: 'orange',
      amount: contributions,
      percentage: contributionPercentage,
    },
    {
      name: 'Growth',
      color: 'lavender',
      amount: growth,
      percentage: growthPercentage,
    },
    {
      name: 'Principal',
      color: 'primaryHighlight',
      amount: principal,
      percentage: principalPercentage,
    },
  ];
};
