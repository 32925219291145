import styled, { css } from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

import type { IconDirection } from './types';

export const IconContainer = styled.div<{ direction: IconDirection }>`
  position: absolute;
  margin-top: ${getDimension('size2')};
  height: ${getDimension('size9')};

  ${({ direction }) =>
    direction === 'left'
      ? css`
          padding-left: 0;
          left: ${getDimension('size2')};
        `
      : css`
          padding-right: 0;
          right: ${getDimension('size2')};
        `}
`;
