/**
 * Calculates the EOY annual dividend yield (e.g. 5% -> 5.10%)
 * @param annualDividendYield - Annual dividend yield
 * @param expectedDividendGrowthRate - Expected dividend growth rate
 * @returns Annual dividend yield
 */

export const getAnnualDividendYield = (
  annualDividendYield: string | number,
  expectedDividendGrowthRate: string | number
) => {
  const resolvedAnnualDividendYield = Number(annualDividendYield);
  const resolvedExpectedDividendGrowthRate = Number(expectedDividendGrowthRate);

  const annualDividendYieldPercentage = resolvedAnnualDividendYield / 100;
  const expectedDividendGrowthRatePercentage = resolvedExpectedDividendGrowthRate / 100;

  const newAnnualDividendYieldPercentage =
    annualDividendYieldPercentage * expectedDividendGrowthRatePercentage +
    annualDividendYieldPercentage;

  return newAnnualDividendYieldPercentage * 100;
};
