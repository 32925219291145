import styled, { css } from 'styled-components';

import type { Color } from 'styled/types';

export const StyledTableRow = styled.tr<{ backgroundColor?: Color; isLined?: boolean }>`
  text-align: left;

  // If isLined is true, we show lines in between the table rows
  ${({ isLined }) =>
    isLined &&
    css`
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      &:last-child {
        border-bottom: none;
      }
    `}

  ${({ backgroundColor, theme }) =>
    backgroundColor && `background-color: ${theme.colors[backgroundColor]};`}
`;
