export const SVGChartLineUp = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M74.7489 14.5598H14.2307C12.7715 14.5598 11.3722 15.1395 10.3404 16.1712C9.30864 17.203 8.729 18.6023 8.729 20.0615V69.5764C8.729 71.0355 9.30864 72.4349 10.3404 73.4666C11.3722 74.4984 12.7715 75.078 14.2307 75.078H74.7489C76.208 75.078 77.6074 74.4984 78.6391 73.4666C79.6709 72.4349 80.2505 71.0355 80.2505 69.5764V20.0615C80.2505 18.6023 79.6709 17.203 78.6391 16.1712C77.6074 15.1395 76.208 14.5598 74.7489 14.5598ZM69.2472 66.8255H19.7323C19.0027 66.8255 18.3031 66.5357 17.7872 66.0198C17.2713 65.504 16.9815 64.8043 16.9815 64.0747V25.5631C16.9815 24.8336 17.2713 24.1339 17.7872 23.618C18.3031 23.1021 19.0027 22.8123 19.7323 22.8123C20.4619 22.8123 21.1616 23.1021 21.6774 23.618C22.1933 24.1339 22.4831 24.8336 22.4831 25.5631V51.9332L34.2911 40.1219C34.5465 39.8661 34.8499 39.6632 35.1839 39.5248C35.5178 39.3863 35.8758 39.3151 36.2373 39.3151C36.5988 39.3151 36.9567 39.3863 37.2907 39.5248C37.6246 39.6632 37.928 39.8661 38.1835 40.1219L44.4898 46.4316L59.8566 31.0648H52.7422C52.0127 31.0648 51.313 30.775 50.7971 30.2591C50.2812 29.7432 49.9914 29.0435 49.9914 28.3139C49.9914 27.5844 50.2812 26.8847 50.7971 26.3688C51.313 25.8529 52.0127 25.5631 52.7422 25.5631H66.4964C67.2259 25.5631 67.9256 25.8529 68.4415 26.3688C68.9574 26.8847 69.2472 27.5844 69.2472 28.3139V42.0681C69.2472 42.7976 68.9574 43.4973 68.4415 44.0132C67.9256 44.5291 67.2259 44.8189 66.4964 44.8189C65.7668 44.8189 65.0671 44.5291 64.5512 44.0132C64.0354 43.4973 63.7456 42.7976 63.7456 42.0681V34.9538L46.436 52.2668C46.1805 52.5225 45.8771 52.7254 45.5432 52.8639C45.2092 53.0023 44.8513 53.0736 44.4898 53.0736C44.1283 53.0736 43.7703 53.0023 43.4364 52.8639C43.1024 52.7254 42.799 52.5225 42.5435 52.2668L36.2373 45.9571L22.4831 59.7112V61.3239H69.2472C69.9768 61.3239 70.6765 61.6137 71.1923 62.1296C71.7082 62.6455 71.998 63.3451 71.998 64.0747C71.998 64.8043 71.7082 65.504 71.1923 66.0198C70.6765 66.5357 69.9768 66.8255 69.2472 66.8255Z"
      fill="url(#paint0_linear_27_260)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_27_260"
        x1="44.4898"
        y1="14.5598"
        x2="44.4898"
        y2="75.078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC7420" />
        <stop offset="1" stopColor="#FAAC35" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGChartLineUp;
