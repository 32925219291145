/**
 * Calculates the EOY total number of shares held
 * @param previousNumberOfShares - Previous number of shares
 * @param annualContribution - Annual contribution
 * @param sharePrice - Share price
 * @returns Number of shares
 */

export const getNumberOfShares = (
  previousNumberOfShares: number | string,
  annualContribution: number | string,
  sharePrice: number | string
) => {
  const resolvedPreviousNumberOfShares = Number(previousNumberOfShares);
  const resolvedAnnualContribution = Number(annualContribution);
  const resolvedSharePrice = Number(sharePrice);

  return resolvedPreviousNumberOfShares + resolvedAnnualContribution / resolvedSharePrice;
};
