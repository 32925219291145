import { getAnnualDividend } from './getAnnualDividend';
import { getAnnualDividendYield } from './getAnnualDividendYield';
import { getNumberOfShares } from './getNumberOfShares';
import { getNumberOfSharesReinvested } from './getNumberOfSharesReinvested';
import { getSharePrice } from './getSharePrice';
import { getAccumulatedAnnualDividends } from './getAccumulatedAnnualDividends';

function getAccumulatedDividends(
  sharePrice: number,
  numberOfShares: number,
  holdingPeriod: number,
  annualDividendYield: number,
  annualContribution: number,
  dividendReinvestmentPlan: 'yes' | 'no',
  expectedAnnualStockAppreciation: number,
  expectedDividendGrowthRate: number,
  previousTotalDividends: number,
  totalDividends: number,
  currentYear: number
): number {
  if (currentYear <= holdingPeriod) {
    const annualDividend = getAnnualDividend(sharePrice, numberOfShares, annualDividendYield);
    const accumulatedDividends = totalDividends + annualDividend;

    const updatedNumberOfShares =
      dividendReinvestmentPlan === 'yes'
        ? getNumberOfSharesReinvested(
            sharePrice,
            numberOfShares,
            annualContribution,
            previousTotalDividends,
            accumulatedDividends
          )
        : getNumberOfShares(numberOfShares, annualContribution, sharePrice);

    const updatedSharePrice = getSharePrice(sharePrice, expectedAnnualStockAppreciation);

    const updatedAnnualDividendYield = getAnnualDividendYield(
      annualDividendYield,
      expectedDividendGrowthRate
    );

    // Total accummulated dividends amount for the current year to be used in the next iteration as previous dividends
    const previousYearDividends =
      dividendReinvestmentPlan === 'yes'
        ? getAccumulatedAnnualDividends(
            sharePrice,
            numberOfShares,
            annualDividendYield,
            previousTotalDividends
          )
        : 0;

    return getAccumulatedDividends(
      updatedSharePrice,
      updatedNumberOfShares,
      holdingPeriod,
      updatedAnnualDividendYield,
      annualContribution,
      dividendReinvestmentPlan,
      expectedAnnualStockAppreciation,
      expectedDividendGrowthRate,
      previousYearDividends,
      accumulatedDividends,
      currentYear + 1
    );
  }
  return totalDividends;
}

export const getTotalDividendReturn = (
  sharePrice: number | string,
  numberOfShares: number | string,
  holdingPeriod: number,
  annualDividendYield: number | string,
  annualContribution: number | string,
  dividendReinvestmentPlan: 'yes' | 'no',
  expectedAnnualStockAppreciation: number | string,
  expectedDividendGrowthRate: number | string
) => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualDividendYield = Number(annualDividendYield);
  const resolvedAnnualContribution = Number(annualContribution);
  const resolvedExpectedAnnualStockAppreciation = Number(expectedAnnualStockAppreciation);
  const resolvedExpectedDividendGrowthRate = Number(expectedDividendGrowthRate);

  const accumulatedDividends = getAccumulatedDividends(
    resolvedSharePrice,
    resolvedNumberOfShares,
    holdingPeriod,
    resolvedAnnualDividendYield,
    resolvedAnnualContribution,
    dividendReinvestmentPlan,
    resolvedExpectedAnnualStockAppreciation,
    resolvedExpectedDividendGrowthRate,
    0,
    0,
    1
  );

  return accumulatedDividends;
};
