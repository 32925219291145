export const SVGClock = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.4898 8.26709C37.417 8.26709 30.503 10.3644 24.6222 14.2939C18.7413 18.2233 14.1578 23.8084 11.4511 30.3428C8.7445 36.8772 8.03632 44.0675 9.41615 51.0044C10.796 57.9413 14.2019 64.3133 19.2031 69.3145C24.2043 74.3157 30.5763 77.7216 37.5132 79.1015C44.4501 80.4813 51.6404 79.7731 58.1748 77.0665C64.7092 74.3598 70.2943 69.7763 74.2237 63.8954C78.1532 58.0146 80.2505 51.1007 80.2505 44.0278C80.2405 34.5466 76.4697 25.4565 69.7654 18.7522C63.0611 12.048 53.971 8.2771 44.4898 8.26709ZM63.7456 46.7787H44.4898C43.7602 46.7787 43.0605 46.4889 42.5446 45.973C42.0288 45.4571 41.7389 44.7574 41.7389 44.0278V24.7721C41.7389 24.0425 42.0288 23.3428 42.5446 22.8269C43.0605 22.311 43.7602 22.0212 44.4898 22.0212C45.2193 22.0212 45.919 22.311 46.4349 22.8269C46.9508 23.3428 47.2406 24.0425 47.2406 24.7721V41.277H63.7456C64.4751 41.277 65.1748 41.5668 65.6907 42.0827C66.2066 42.5986 66.4964 43.2983 66.4964 44.0278C66.4964 44.7574 66.2066 45.4571 65.6907 45.973C65.1748 46.4889 64.4751 46.7787 63.7456 46.7787Z"
      fill="url(#paint0_linear_27_257)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_27_257"
        x1="44.4898"
        y1="8.26709"
        x2="44.4898"
        y2="79.7886"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.427083" stopColor="#ED6268" />
        <stop offset="1" stopColor="#D24C84" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGClock;
