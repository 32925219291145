import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

import type { Size } from 'styled/types';

export const StyledTable = styled.table<{ marginTop: Size; isLined?: boolean }>`
  width: 100%;
  border-collapse: collapse;
  position: relative;

  &::before {
    content: '';
    width: calc(100% - ${getDimension('size6')});
    height: 1px;
    position: absolute;
    // If isLined is true, we don't show the single line just below the table header, default is the line is shown
    background-color: ${({ isLined }) => (isLined ? 'transparent' : 'rgba(0, 0, 0, 0.3)')};
    top: 0;
  }

  margin-top: ${props => getDimension(`size${props.marginTop}`)};

  &,
  & p {
    font-weight: ${props => props.theme.fontWeight.normal};
    font-size: ${props => props.theme.fontSize.xxs};
  }
`;
