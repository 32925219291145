export const getAnnualGrowth = (
  sharePrice: number | string,
  numberOfShares: number | string,
  expectedAnnualStockAppreciation: number | string
) => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedExpectedAnnualStockAppreciation = Number(expectedAnnualStockAppreciation);
  const expectedAnnualStockAppreciationPercentage = resolvedExpectedAnnualStockAppreciation / 100;

  return resolvedSharePrice * resolvedNumberOfShares * expectedAnnualStockAppreciationPercentage;
};
