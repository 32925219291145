import { useMemo, type FC } from 'react';

import { FlexContainer } from 'components/FlexContainer';

import { Fieldset } from './Fieldset.styled';
import { RadioOption } from './RadioOption';

import type { FormRadioProps } from './types';

export const FormRadio: FC<FormRadioProps> = ({ onChange, label, name, value, options }) => {
  const renderedRadioOptions = useMemo(
    () =>
      options.map(option => (
        <RadioOption
          key={option.value}
          value={value}
          onChange={onChange}
          name={name}
          option={option}
        />
      )),
    [name, value, onChange, options]
  );

  return (
    <Fieldset>
      <legend>{label}</legend>
      <FlexContainer>{renderedRadioOptions}</FlexContainer>
    </Fieldset>
  );
};
