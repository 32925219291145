import { useForm } from 'hooks/useForm';
import { Box } from 'components/Box';

import { ArrowDivider, Container } from './Container';
import { DividendCalculatorForm } from './Form';
import { initialFormState } from './Form/constants';
import { MonthlyIncome } from './MonthlyIncome';
import { DividendReturns } from './DividendReturns';

import type { FC } from 'react';

export const DividendCalculator: FC = () => {
  const { state, onChange, onNumberChange, onBlur } = useForm(initialFormState);

  return (
    <Container>
      <Box paddingTop={10} paddingBottom={10} paddingLeft={8} paddingRight={5}>
        <DividendCalculatorForm
          state={state}
          onChange={onChange}
          onNumberChange={onNumberChange}
          onBlur={onBlur}
        />
      </Box>
      <ArrowDivider />
      <div>
        <DividendReturns
          sharePrice={state.sharePrice}
          numberOfShares={state.numberOfShares}
          holdingPeriod={state.holdingPeriod}
          annualDividendYield={state.annualDividendYield}
          annualContribution={state.annualContribution}
          dividendReinvestmentPlan={state.dividendReinvestmentPlan}
          expectedAnnualStockAppreciation={state.expectedAnnualStockAppreciation}
          expectedDividendGrowthRate={state.expectedDividendGrowthRate}
        />
        <Box padding={5}>
          <MonthlyIncome
            sharePrice={state.sharePrice}
            numberOfShares={state.numberOfShares}
            holdingPeriod={state.holdingPeriod}
            annualDividendYield={state.annualDividendYield}
            annualContribution={state.annualContribution}
            dividendReinvestmentPlan={state.dividendReinvestmentPlan}
            expectedAnnualStockAppreciation={state.expectedAnnualStockAppreciation}
            expectedDividendGrowthRate={state.expectedDividendGrowthRate}
          />
        </Box>
      </div>
    </Container>
  );
};
