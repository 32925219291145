/**
 * Calculates the EOY total number of shares held with DRP = true
 * @param sharePrice - Share price
 * @param numberOfShares - Number of shares
 * @param annualContribution - Annual contribution
 * @param previousDividends - Previous dividends
 * @param totalDividends - Total dividends
 * @returns Number of shares
 */
export const getNumberOfSharesReinvested = (
  sharePrice: number | string,
  numberOfShares: number | string,
  annualContribution: number | string,
  previousDividends: number,
  totalDividends: number
) => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualContribution = Number(annualContribution);
  const resolvedPreviousDividends = Number(previousDividends);
  const resolvedTotalDividends = Number(totalDividends);

  if (!resolvedPreviousDividends) {
    return (
      resolvedNumberOfShares +
      (resolvedTotalDividends + resolvedAnnualContribution) / resolvedSharePrice
    );
  }

  return (
    resolvedNumberOfShares +
    (resolvedTotalDividends + resolvedAnnualContribution - resolvedPreviousDividends) /
      resolvedSharePrice
  );
};
