import type { UseFormUpdateAction } from './types';

export function formReducer<T extends {}>(state: T, action: UseFormUpdateAction<T>): T {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        [action.field]: action.payload,
      };

    default:
      return state;
  }
}
