import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

import type { Color, Size } from 'styled/types';

export const Dot = styled.div<{ color: Color; size?: Size }>`
  width: ${({ size }) => getDimension(`size${size ?? 4}`)};
  height: ${({ size }) => getDimension(`size${size ?? 4}`)};
  border-radius: ${getDimension('size5')};
  background: ${({ theme, color }) => theme.colors[color]};
`;
