import { getAnnualDividend } from './getAnnualDividend';
import { getSharePrice } from './getSharePrice';
import { getAnnualDividendYield } from './getAnnualDividendYield';
import { getAccumulatedAnnualDividends } from './getAccumulatedAnnualDividends';
import { getNumberOfSharesReinvested } from './getNumberOfSharesReinvested';

import type { MonthlyDividendIncome } from 'components/DividendCalculator/types';

/**
 * Calculates the average monthly dividend income over the holding period with drp = true
 * @param sharePrice - Share price
 * @param numberOfShares - Number of shares
 * @param holdingPeriod - Holding period
 * @param annualDividendYield - Annual dividend yield
 * @param annualContribution - Annual contribution
 * @param expectedAnnualStockAppreciation - Expected annual stock appreciation
 * @param expectedDividendGrowthRate - Expected dividend growth rate
 * @returns Monthly dividend income as an array of objects for each year in the holding period
 */
export const getMonthlyDividendIncomeReinvested = (
  sharePrice: number | string,
  numberOfShares: number | string,
  holdingPeriod: number,
  annualDividendYield: number | string,
  annualContribution: number | string,
  expectedDividendGrowthRate: number | string,
  expectedAnnualStockAppreciation: number | string
): MonthlyDividendIncome[] => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualDividendYield = Number(annualDividendYield);
  const resolvedExpectedDividendGrowthRate = Number(expectedDividendGrowthRate);
  const resolvedExpectedAnnualStockAppreciation = Number(expectedAnnualStockAppreciation);
  const resolvedAnnualContribution = Number(annualContribution);

  const income = [];
  let runningSharePrice = resolvedSharePrice;
  let runningNumberOfShares = resolvedNumberOfShares;
  let runningAnnualDividendYield = resolvedAnnualDividendYield;
  let previousDividends = 0;

  while (income.length < holdingPeriod) {
    // Dividends earned for the current year
    const annualDividend = getAnnualDividend(
      runningSharePrice,
      runningNumberOfShares,
      runningAnnualDividendYield
    );

    // Dividends earned for the current year divided by 12 to get monthly dividend income
    const monthlyDividendIncome = annualDividend / 12;

    income.push({
      year: income.length + 1,
      income: monthlyDividendIncome,
    });

    // Total accummulated dividends amount for the current year + previous years dividends
    const totalDividends = getAccumulatedAnnualDividends(
      runningSharePrice,
      runningNumberOfShares,
      runningAnnualDividendYield,
      previousDividends
    );

    // Total number of shares including the dividends reinvested
    runningNumberOfShares = getNumberOfSharesReinvested(
      runningSharePrice,
      runningNumberOfShares,
      resolvedAnnualContribution,
      previousDividends,
      totalDividends
    );

    // End of year share price after stock appreciation
    runningSharePrice = getSharePrice(runningSharePrice, resolvedExpectedAnnualStockAppreciation);

    // Dividend yield after dividend growth rate from previous year
    runningAnnualDividendYield = getAnnualDividendYield(
      runningAnnualDividendYield,
      resolvedExpectedDividendGrowthRate
    );

    // Total accummulated dividends for the current year to be used in the next iteration
    previousDividends = totalDividends;
  }

  return income;
};
