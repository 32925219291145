import type { DividendCalculatorFormState } from '../types';

export const initialFormState: DividendCalculatorFormState = {
  sharePrice: 100,
  numberOfShares: 100,
  holdingPeriod: 10,
  annualDividendYield: 5,
  annualContribution: 1000,
  dividendReinvestmentPlan: 'no',
  expectedAnnualStockAppreciation: 2,
  expectedDividendGrowthRate: 2,
};

export const holdingPeriodOptions = [
  { value: 1, label: '1 year' },
  { value: 2, label: '2 years' },
  { value: 3, label: '3 years' },
  { value: 4, label: '4 years' },
  { value: 5, label: '5 years' },
  { value: 6, label: '6 years' },
  { value: 7, label: '7 years' },
  { value: 8, label: '8 years' },
  { value: 9, label: '9 years' },
  { value: 10, label: '10 years' },
];

export const dividendReinvestmentPlanOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];
