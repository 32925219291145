import { useMemo, type FC } from 'react';
import { ColumnChart } from 'components/ColumnChart';

import type { MonthlyDividendIncome } from '../types';

interface MonthlyIncomeChartProps {
  dividendReinvestmentPlan: 'yes' | 'no';
  monthlyDividendIncomes: MonthlyDividendIncome[];
}

export const MonthlyIncomeChart: FC<MonthlyIncomeChartProps> = ({
  monthlyDividendIncomes,
  dividendReinvestmentPlan,
}) => {
  const years = useMemo(
    () => monthlyDividendIncomes.map(({ year }) => `${year}`),
    [monthlyDividendIncomes]
  );

  const data = useMemo(
    () => monthlyDividendIncomes.map(({ income }) => Math.round(income * 100) / 100),
    [monthlyDividendIncomes]
  );

  const title =
    dividendReinvestmentPlan === 'yes' ? 'Dividend income reinvested' : 'Average monthly income';

  return <ColumnChart title={title} columnsTitle="Years" categories={years} data={data} />;
};
