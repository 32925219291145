import { theme } from 'styled/theme';
import type { Options } from 'highcharts';

export function getColumnChartConfig(
  title: string,
  columnsTitle: string,
  categories: string[],
  data: number[]
): Options {
  return {
    exporting: { enabled: false },
    credits: { enabled: false }, // remove Highcharts.com watermark

    colors: [theme.colors.grey],
    chart: {
      height: 300,
      plotShadow: false,
      style: {
        fontFamily: '"Nunito Sans", Helvetica, sans-serif',
        fontSize: theme.fontSize.xxs,
      },
    },

    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">Year {point.key}</th></tr>',
      pointFormat:
        '<tr><td>Avg. monthly income: ' +
        '</td>' +
        '<td style="text-align: right"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      valueDecimals: 2,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: { text: undefined },
    },
    series: [
      {
        type: 'column',
        data,
        name: columnsTitle,
      },
    ],
  };
}
