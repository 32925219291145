import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const VerticalLine = styled.div`
  display: inline-block;
  height: 50%;
  border-left: 2px solid ${({ theme }) => theme.colors.greyLight};
  margin-left: ${getDimension('size4')};
`;

export const HorizontalLine = styled.div`
  display: inline-block;
  width: 50%;
  border-top: 2px solid ${({ theme }) => theme.colors.greyLight};
  margin-top: ${getDimension('size4')};
`;

export const Arrow = styled.div`
  border: solid ${({ theme }) => theme.colors.greyLight};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: ${getDimension('size4')};
  transform: rotate(-45deg);
  margin-top: 6px;
  margin-bottom: 6px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: rotate(45deg);
    margin: 0px;
    margin-left: 6px;
    margin-right: 6px;
  }
`;
