import { FormInput, FormRadio, FormSelect } from 'components/Form';
import { FlexContainer } from 'components/FlexContainer';

import { dividendReinvestmentPlanOptions, holdingPeriodOptions } from './constants';

import type { DividendCalculatorFormState } from '../types';
import type { FC } from 'react';

interface DividendCalculatorFormProps {
  state: DividendCalculatorFormState;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onNumberChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    maxLength?: number
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const DividendCalculatorForm: FC<DividendCalculatorFormProps> = ({
  state,
  onChange,
  onNumberChange,
  onBlur,
}) => (
  <FlexContainer direction="column" gap={4}>
    <FormInput
      name="sharePrice"
      label="Unit/share price"
      value={state.sharePrice}
      onChange={onNumberChange}
      maxLength={6}
      onBlur={onBlur}
      icon="currency"
      iconDirection="left"
    />
    <FormInput
      name="numberOfShares"
      label="Number of shares"
      value={state.numberOfShares}
      onChange={onNumberChange}
      maxLength={6}
      onBlur={onBlur}
    />
    <FormSelect
      name="holdingPeriod"
      label="Holding period"
      value={state.holdingPeriod}
      onChange={onChange}
      options={holdingPeriodOptions}
    />
    <FormInput
      name="annualDividendYield"
      label="Annual dividend yield"
      value={state.annualDividendYield}
      onChange={onNumberChange}
      maxLength={3}
      onBlur={onBlur}
      icon="percentage"
      iconDirection="right"
    />
    <FormInput
      name="annualContribution"
      label="Annual contribution"
      value={state.annualContribution}
      onChange={onNumberChange}
      onBlur={onBlur}
      maxLength={8}
      icon="currency"
      iconDirection="left"
    />
    <FormRadio
      onChange={onChange}
      name="dividendReinvestmentPlan"
      label="Dividend reinvestment plan"
      value={state.dividendReinvestmentPlan}
      options={dividendReinvestmentPlanOptions}
    />
    <FormInput
      name="expectedAnnualStockAppreciation"
      label="Expected annual stock appreciation"
      value={state.expectedAnnualStockAppreciation}
      onChange={onNumberChange}
      maxLength={3}
      onBlur={onBlur}
      icon="percentage"
      iconDirection="right"
    />
    <FormInput
      name="expectedDividendGrowthRate"
      label="Expected dividend growth rate"
      value={state.expectedDividendGrowthRate}
      onChange={onNumberChange}
      maxLength={3}
      onBlur={onBlur}
      icon="percentage"
      iconDirection="right"
    />
  </FlexContainer>
);
