import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';
import { StripGraph } from 'components/StripGraph';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { getFormattedAmount } from 'helpers/getFormattedAmount';
import { getTotalDividendReturn } from 'helpers/dividendCalculator/getTotalDividendReturn';

import { DividendReturnsTable } from './DividendReturnsTable';
import { getDividendReturnsBreakdown } from './getDividendReturnsBreakdown';

import type { FC } from 'react';
import type { StripProps } from 'components/StripGraph/types';
import type { DividendCalculatorFormState } from '../types';

type DividendReturnsProps = DividendCalculatorFormState;

export const DividendReturns: FC<DividendReturnsProps> = ({
  sharePrice,
  numberOfShares,
  holdingPeriod,
  annualDividendYield,
  annualContribution,
  dividendReinvestmentPlan,
  expectedAnnualStockAppreciation,
  expectedDividendGrowthRate,
}) => {
  const {
    translations: { currencySymbol },
  } = useContentfulLocale();

  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const dividendReturnsBreakdown = getDividendReturnsBreakdown(
    sharePrice,
    numberOfShares,
    holdingPeriod,
    annualDividendYield,
    annualContribution,
    dividendReinvestmentPlan,
    expectedAnnualStockAppreciation,
    expectedDividendGrowthRate
  );

  const sections: StripProps[] = useMemo(
    () => dividendReturnsBreakdown.map(({ percentage, color }) => ({ percentage, color })),
    [dividendReturnsBreakdown]
  );

  const dividendReturn = getTotalDividendReturn(
    sharePrice,
    numberOfShares,
    holdingPeriod,
    annualDividendYield,
    annualContribution,
    dividendReinvestmentPlan,
    expectedAnnualStockAppreciation,
    expectedDividendGrowthRate
  );

  const formattedEstimatedDividendReturn = getFormattedAmount(dividendReturn, 2, currencySymbol);

  return (
    <Box
      paddingLeft={isMobile ? 3 : 15}
      paddingRight={isMobile ? 3 : 15}
      paddingTop={5}
      paddingBottom={10}
    >
      <FlexContainer direction="column" alignItems="center">
        <h6>Estimated dividend return</h6>
        <h1>{formattedEstimatedDividendReturn}</h1>
      </FlexContainer>
      <StripGraph sections={sections} />
      <DividendReturnsTable dividendReturnsBreakdown={dividendReturnsBreakdown} />
    </Box>
  );
};
