export const getFormattedAmount = (number: number, decimal?: number, currencySymbol?: string) => {
  const decimalVal = decimal === undefined ? 2 : decimal;
  let formattedNumber = '0';
  if (number) {
    formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: decimalVal,
      maximumFractionDigits: decimalVal,
    });
  }

  return `${currencySymbol || '$'}${formattedNumber}`;
};
