import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import highchartsAccessibility from 'highcharts/modules/accessibility';

import { getColumnChartConfig } from './getColumnChartConfig';

import type { FC } from 'react';

interface ColumnChartProps {
  title: string;
  columnsTitle: string;
  categories: string[];
  data: number[];
}

if (typeof Highcharts === 'object') {
  highchartsAccessibility(Highcharts); // Execute the bell curve module
}

export const ColumnChart: FC<ColumnChartProps> = ({ title, columnsTitle, categories, data }) => {
  const config = getColumnChartConfig(title, columnsTitle, categories, data);

  return <HighchartsReact highcharts={Highcharts} options={config} />;
};
