const SVGArrowDown = () => (
  <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3054 1.17929L7.65269 7.62076L1 1.17929"
      stroke="#767689"
      strokeWidth="0.964775"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGArrowDown;
