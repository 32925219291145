import { useMemo } from 'react';

import { StyledTableRow } from '../styled/StyledTableRow.styled';
import { TableHeader } from '../styled/TableHeader.styled';
import { TableCellAccent } from '../styled/TableCellAccent.styled';

import { TableCell } from './TableCell';

import type { FC } from 'react';
import type { TableColumn } from '../types';

export const TableHeaderRow: FC<{ columns: TableColumn[] }> = ({ columns }) => {
  const mappedColumns = useMemo(
    () =>
      columns.map(({ id, title, width, accentColor }) => (
        <TableHeader key={id}>
          {accentColor && <TableCellAccent accentColor={accentColor} />}
          <TableCell width={width} as="div">
            {title}
          </TableCell>
        </TableHeader>
      )),
    [columns]
  );

  return <StyledTableRow>{mappedColumns}</StyledTableRow>;
};
