import { getNumberOfShares } from './getNumberOfShares';
import { getSharePrice } from './getSharePrice';
import { getAnnualGrowth } from './getAnnualGrowth';
import { getNumberOfSharesReinvested } from './getNumberOfSharesReinvested';
import { getAnnualDividend } from './getAnnualDividend';
import { getAnnualDividendYield } from './getAnnualDividendYield';

function getAccumulatedGrowth(
  sharePrice: number,
  numberOfShares: number,
  holdingPeriod: number,
  annualDividendYield: number,
  annualContribution: number,
  dividendReinvestmentPlan: 'yes' | 'no',
  expectedAnnualStockAppreciation: number,
  expectedDividendGrowthRate: number,
  previousDividends: number,
  currentYear: number,
  totalGrowth: number
): number {
  if (currentYear <= holdingPeriod) {
    const growth = getAnnualGrowth(sharePrice, numberOfShares, expectedAnnualStockAppreciation);

    const accGrowth = totalGrowth + growth;

    const totalDividends =
      getAnnualDividend(sharePrice, numberOfShares, annualDividendYield) + previousDividends;

    const updatedNumberOfShares =
      dividendReinvestmentPlan === 'yes'
        ? getNumberOfSharesReinvested(
            sharePrice,
            numberOfShares,
            annualContribution,
            previousDividends,
            totalDividends
          )
        : getNumberOfShares(numberOfShares, annualContribution, sharePrice);

    const updatedSharePrice = getSharePrice(sharePrice, expectedAnnualStockAppreciation);

    const updatedAnnualDividendYield = getAnnualDividendYield(
      annualDividendYield,
      expectedDividendGrowthRate
    );

    return getAccumulatedGrowth(
      updatedSharePrice,
      updatedNumberOfShares,
      holdingPeriod,
      updatedAnnualDividendYield,
      annualContribution,
      dividendReinvestmentPlan,
      expectedAnnualStockAppreciation,
      expectedDividendGrowthRate,
      previousDividends,
      currentYear + 1,
      accGrowth
    );
  }
  return totalGrowth;
}

export const getTotalGrowth = (
  sharePrice: number | string,
  numberOfShares: number | string,
  holdingPeriod: number,
  annualDividendYield: number | string,
  annualContribution: number | string,
  dividendReinvestmentPlan: 'yes' | 'no',
  expectedAnnualStockAppreciation: number | string,
  expectedDividendGrowthRate: number | string
) => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualDividendYield = Number(annualDividendYield);
  const resolvedAnnualContribution = Number(annualContribution);
  const resolvedExpectedAnnualStockAppreciation = Number(expectedAnnualStockAppreciation);
  const resolvedExpectedDividendGrowthRate = Number(expectedDividendGrowthRate);

  const accumulatedGrowth = getAccumulatedGrowth(
    resolvedSharePrice,
    resolvedNumberOfShares,
    holdingPeriod,
    resolvedAnnualDividendYield,
    resolvedAnnualContribution,
    dividendReinvestmentPlan,
    resolvedExpectedAnnualStockAppreciation,
    resolvedExpectedDividendGrowthRate,
    0, // previous dividends
    1, // current year
    0 // total growth
  );

  return accumulatedGrowth;
};
