/**
 * Calculates the dividend return for a given year
 * @param sharePrice - Share price
 * @param numberOfShares - Number of shares
 * @param annualDividendYield - Annual dividend yield
 * @returns Annual dividend
 */
export const getAnnualDividend = (
  sharePrice: string | number,
  numberOfShares: string | number,
  annualDividendYield: string | number
) => {
  const resolvedSharePrice = Number(sharePrice);
  const resolvedNumberOfShares = Number(numberOfShares);
  const resolvedAnnualDividendYield = Number(annualDividendYield);

  const annualDividendYieldPercentage = resolvedAnnualDividendYield / 100;

  const annualDividend =
    annualDividendYieldPercentage * resolvedSharePrice * resolvedNumberOfShares;

  return annualDividend;
};
