import CalendarBlank from 'images/dividend-calculator/CalendarBlank';
import ChartLineUp from 'images/dividend-calculator/ChartLineUp';
import Clock from 'images/dividend-calculator/Clock';
import CurrencyCircleDollar from 'images/dividend-calculator/CurrencyCircleDollar';
import Note from 'images/dividend-calculator/Note';
import Percent from 'images/dividend-calculator/Percent';

export const dividendCalculatorCards = [
  {
    cardIdentifier: 'holding-period',
    title: 'Holding period',
    text: 'How many years will you be investing in your dividend portfolio? The dividend calculator can go up to a maximum of 10 years.',
    image: <CalendarBlank />,
  },
  {
    cardIdentifier: 'annual-dividend-yield',
    title: 'Annual dividend yield',
    text: 'How much dividend income will you earn in dividend payouts per year for every dollar invested in the stock?',
    image: <Clock />,
  },
  {
    cardIdentifier: 'drp-drip',
    title: 'DRP / DRIP',
    text: 'Have you opted in to a dividend reinvestment plan?',
    image: <Note />,
  },
  {
    cardIdentifier: 'annual-contribution',
    title: 'Annual contribution',
    text: 'How much money will you be contributing to your investment every year?',
    image: <CurrencyCircleDollar />,
  },
  {
    cardIdentifier: 'stock-price-appreciation',
    title: 'Stock price appreciation',
    text: 'How much do you expect the price of your shares to increase each year? It is reasonable to estimate between 6% to 8%.',
    image: <ChartLineUp />,
  },
  {
    cardIdentifier: 'dividend-growth-rate',
    title: 'Dividend growth rate',
    text: 'How much do you estimate your dividend payouts to increase each year? It is reasonable to estimate between 3% to 5%.',
    image: <Percent />,
  },
];
