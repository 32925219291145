import { useMemo } from 'react';

import { Box } from 'components/Box';
import { Strip } from './styled/Strip.styled';
import { Container } from './styled/Container.styled';

import type { FC } from 'react';
import type { StripGraphProps, StripProps } from './types';

export const StripGraph: FC<StripGraphProps> = ({ sections }) => {
  const mappedSections = useMemo(
    () =>
      sections.map(({ percentage, color }: StripProps) => (
        <Strip key={color} percentage={percentage} color={color} />
      )),
    [sections]
  );

  return (
    <Box>
      <Container>{mappedSections}</Container>
    </Box>
  );
};
