import { useMemo } from 'react';
import styled from 'styled-components';

import { Table } from 'components/Table';
import { FlexContainer } from 'components/FlexContainer';
import { Dot } from 'components/Dot';
import { Box } from 'components/Box';

import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { getFormattedAmount } from 'helpers/getFormattedAmount';

import type { FC } from 'react';
import type { TableColumn } from 'components/Table';
import type { DividendReturnBreakdown } from './getDividendReturnsBreakdown';

const columns: TableColumn[] = [
  { id: 'category', width: 60 },
  { id: 'amount', width: 25 },
  { id: 'percentage', width: 15 },
];

interface DividendReturnsTableProps {
  dividendReturnsBreakdown: DividendReturnBreakdown[];
}

const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`;

export const DividendReturnsTable: FC<DividendReturnsTableProps> = ({
  dividendReturnsBreakdown,
}) => {
  const {
    translations: { currencySymbol },
  } = useContentfulLocale();

  const rows = useMemo(
    () =>
      dividendReturnsBreakdown.map(breakdown => ({
        id: breakdown.name,
        cells: [
          <FlexContainer key={`${breakdown.name}-name`} alignItems="center">
            <Box marginRight={2}>
              <Dot color={breakdown.color} />
            </Box>{' '}
            {breakdown.name}
          </FlexContainer>,
          <FlexContainer key={`${breakdown.name}-amount`}>
            <strong>{getFormattedAmount(breakdown.amount, 2, currencySymbol)}</strong>
          </FlexContainer>,
          <TextContainer key={`${breakdown.percentage}-percentage`}>
            {breakdown.percentage.toFixed(0)}%
          </TextContainer>,
        ],
      })),
    [currencySymbol, dividendReturnsBreakdown]
  );

  return <Table columns={columns} rows={rows} isLined />;
};
