import { useMemo, type FC } from 'react';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { getMonthlyDividendIncomeReinvested } from 'helpers/dividendCalculator/getMonthlyDividendIncomeReinvested';
import { getAverageMonthlyIncome } from 'helpers/dividendCalculator/getAverageMonthlyIncome';
import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';
import { MonthlyIncomeChart } from './MonthlyIncomeChart';
import { MonthlyIncomeTable } from './MonthlyIncomeTable';

import type { DividendCalculatorFormState } from '../types';

type MonthlyIncomeProps = DividendCalculatorFormState;

export const MonthlyIncome: FC<MonthlyIncomeProps> = ({
  sharePrice,
  numberOfShares,
  holdingPeriod,
  annualDividendYield,
  annualContribution,
  dividendReinvestmentPlan,
  expectedAnnualStockAppreciation,
  expectedDividendGrowthRate,
}) => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const monthlyDividendIncomes = useMemo(
    () =>
      dividendReinvestmentPlan === 'yes'
        ? getMonthlyDividendIncomeReinvested(
            sharePrice,
            numberOfShares,
            holdingPeriod,
            annualDividendYield,
            annualContribution,
            expectedAnnualStockAppreciation,
            expectedDividendGrowthRate
          )
        : getAverageMonthlyIncome(
            sharePrice,
            numberOfShares,
            holdingPeriod,
            annualDividendYield,
            annualContribution,
            expectedAnnualStockAppreciation,
            expectedDividendGrowthRate
          ),
    [
      sharePrice,
      numberOfShares,
      holdingPeriod,
      annualDividendYield,
      annualContribution,
      expectedAnnualStockAppreciation,
      expectedDividendGrowthRate,
      dividendReinvestmentPlan,
    ]
  );

  return (
    <>
      <FlexContainer justifyContent="center">
        <Box maxWidth={isMobile ? '90%' : '100%'}>
          <MonthlyIncomeChart
            monthlyDividendIncomes={monthlyDividendIncomes}
            dividendReinvestmentPlan={dividendReinvestmentPlan}
          />
        </Box>
      </FlexContainer>
      <MonthlyIncomeTable monthlyDividendIncomes={monthlyDividendIncomes} />
    </>
  );
};
