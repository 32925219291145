import { useMemo } from 'react';

import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

import { Table } from 'components/Table';
import { FlexContainer } from 'components/FlexContainer';
import { Box } from 'components/Box';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { buildRows } from './buildRows';

import type { FC } from 'react';
import type { MonthlyDividendIncome } from '../types';

interface MonthlyIncomeTableProps {
  monthlyDividendIncomes: MonthlyDividendIncome[];
}

export const MonthlyIncomeTable: FC<MonthlyIncomeTableProps> = ({ monthlyDividendIncomes }) => {
  const {
    translations: { currencySymbol },
  } = useContentfulLocale();

  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const columns = useMemo(() => [{ id: 'year' }, { id: 'amount' }], []);

  const rows = useMemo(
    () => buildRows(monthlyDividendIncomes, currencySymbol),
    [monthlyDividendIncomes, currencySymbol]
  );

  if (!rows.second5Years.length) return <Table columns={columns} rows={rows.first5Years} isLined />;

  return (
    <FlexContainer direction={isMobile ? 'column' : 'row'}>
      <Box>
        <Table columns={columns} rows={rows.first5Years} isLined />
      </Box>
      <Box>
        <Table columns={columns} rows={rows.second5Years} isLined />
      </Box>
    </FlexContainer>
  );
};
