import styled from 'styled-components';
import { Box } from 'components/Box';
import { getDimension } from 'styled/helpers/getDimension';

export const RadioWrapper = styled(Box)`
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${getDimension('size3')};
  transition: background 0.2s ease-in-out;
  margin-right: ${getDimension('size1')};

  &:hover,
  &:focus,
  &:focus-within {
    background: ${({ theme }) => theme.colors.navyBlueHighlight};
  }
`;
