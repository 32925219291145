import { getFormattedAmount } from 'helpers/getFormattedAmount';
import type { TableRow } from 'components/Table';
import type { Translations } from 'helpers/mergeTranslations';
import type { MonthlyDividendIncome } from '../types';

const buildRow = (
  monthlyDividend: MonthlyDividendIncome,
  currencySymbol: Translations['currencySymbol']
) => {
  const formattedAmount = getFormattedAmount(monthlyDividend.income, 2, currencySymbol);
  const row: TableRow = {
    id: `year${monthlyDividend.year}`,
    cells: [
      <>Year {monthlyDividend.year}</>,
      <strong key={`${monthlyDividend.income}`}>{formattedAmount}</strong>,
    ],
  };

  return row;
};

export const buildRows = (
  monthlyDividendIncomes: MonthlyDividendIncome[],
  currencySymbol: Translations['currencySymbol']
) =>
  monthlyDividendIncomes.reduce(
    ({ first5Years, second5Years }, monthlyDividend) => {
      const row = buildRow(monthlyDividend, currencySymbol);

      if (first5Years.length < 5) {
        first5Years.push(row);
      } else {
        second5Years.push(row);
      }

      return { first5Years, second5Years };
    },
    { first5Years: [], second5Years: [] } as {
      first5Years: TableRow[];
      second5Years: TableRow[];
    }
  );
