import { useMemo, type FC } from 'react';

import { FlexContainer } from 'components/FlexContainer';
import ArrowDownSVG from 'images/icons/ArrowDown';

import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { IconContainer } from './IconContainer.styled';

import type { IconDirection, IconType } from './types';

export const Icon: FC<{
  icon: IconType;
  direction?: IconDirection;
}> = ({ icon, direction = 'right' }) => {
  const {
    translations: { currencySymbol },
  } = useContentfulLocale();

  const renderedIcon = useMemo(() => {
    switch (icon) {
      case 'arrowDown':
        return <ArrowDownSVG />;
      case 'currency':
        return <div>{currencySymbol || '$'}</div>;
      case 'percentage':
        return <div>%</div>;
      default:
        return null;
    }
  }, [icon, currencySymbol]);

  return (
    <IconContainer direction={direction}>
      <FlexContainer alignItems="center">{renderedIcon}</FlexContainer>
    </IconContainer>
  );
};
