import { useMemo, type FC } from 'react';

import { Icon } from '../Icon';
import { InputContainer } from '../InputContainer.styled';
import { Select } from './Select.styled';

import type { FormSelectProps } from './types';

export const FormSelect: FC<FormSelectProps> = ({ name, label, value, onChange, options }) => {
  const renderedOptions = useMemo(
    () =>
      options.map(selectOption => (
        <option key={selectOption.value} value={selectOption.value}>
          {selectOption.label || selectOption.value}
        </option>
      )),
    [options]
  );

  return (
    <InputContainer>
      <label htmlFor={name}>{label}</label>
      <Icon icon="arrowDown" direction="right" />
      <Select name={name} value={value} onChange={onChange}>
        {renderedOptions}
      </Select>
    </InputContainer>
  );
};
