import { useTheme } from 'styled-components';

import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { FlexContainer } from 'components/FlexContainer';

import { Arrow, HorizontalLine, VerticalLine } from './ArrowDivider.styled';
import type { FC } from 'react';

export const ArrowDivider: FC = () => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('md'));

  const Line = isMobile ? HorizontalLine : VerticalLine;

  return (
    <FlexContainer
      direction={isMobile ? 'row' : 'column'}
      alignItems={isMobile ? undefined : 'flex-start'}
    >
      <Line />
      <Arrow />
      <Line />
    </FlexContainer>
  );
};
