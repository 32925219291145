import styled, { css } from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

import type { IconDirection } from '../Icon/types';

export const Input = styled.input<{ iconDirection?: IconDirection }>`
  width: 100%;
  height: ${getDimension('size9')};
  padding: 0 ${getDimension('size3')};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: ${getDimension('size2')} 0;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};

  // hides the input arrows for number input field
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  ${({ iconDirection }) =>
    iconDirection === 'left' &&
    css`
      padding-left: ${getDimension('size5')};
    `}

  ${({ iconDirection }) =>
    iconDirection === 'right' &&
    css`
      padding-right: ${getDimension('size6')};
    `}
`;
