import styled from 'styled-components';

import { FlexContainer } from 'components/FlexContainer';
import { getDimension } from 'styled/helpers/getDimension';

export const Container = styled(FlexContainer)`
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 100%;
  height: ${getDimension('size6')};
  overflow: hidden;
`;
