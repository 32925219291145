import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: ${getDimension('size9')};
  display: grid;
  grid-template-columns: 1fr 0.2fr 2fr;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;
