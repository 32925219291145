/**
 * Calculates the EOY share price
 * @param previousSharePrice - Previous share price
 * @param expectedAnnualStockAppreciation - Expected annual stock appreciation
 * @returns Share price
 */
export const getSharePrice = (
  previousSharePrice: number | string,
  expectedAnnualStockAppreciation: number | string
) => {
  const resolvedPreviousSharePrice = Number(previousSharePrice);
  const resolvedExpectedAnnualStockAppreciation = Number(expectedAnnualStockAppreciation);

  const expectedAnnualStockAppreciationPercentage = resolvedExpectedAnnualStockAppreciation / 100;

  return (
    resolvedPreviousSharePrice +
    resolvedPreviousSharePrice * expectedAnnualStockAppreciationPercentage
  );
};
