import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const Select = styled.select`
  width: 100%;
  height: ${getDimension('size9')};
  padding: 0 ${getDimension('size3')};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: ${getDimension('size2')} 0;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};

  &,
  select& {
    appearance: none; /* as we're using our own icon */
  }
`;
